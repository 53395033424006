export default class extends Controller {
  static targets = ["input", "form", "hiddenBody", "hiddenImage"];
  connect(){
    const element = document.getElementById('ai_response_output').parentElement;
      if (element) {
        element.scrollTop = element.scrollHeight;
      }
  }

  submit(event) {
    event.preventDefault(); // Prevent default form submission (we will handle it manually)
    const questionText = this.inputTarget.value.trim();
    if (questionText === "") return;

    // Create a new question div and append it immediately
    const newDiv = document.createElement("div");
    newDiv.className = "response-item";
    newDiv.innerHTML = `
      <div class="col-12 mb-3 mt-1">
        <div class="d-flex align-items-center">
          <span class="account-user-avatar">
            ${this.hiddenImageTarget.value}
          </span>
          <div class="chat-message user-message w-fit-content">${questionText}</div>
        </div>
      </div>
      <div class='d-flex align-items-center justify-content-center loader-div' id='loader_response'>
        <button class="btn btn-primary" type="button" disabled>
          <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
          Loading...
        </button>
      </div>
    `;
    document.getElementById('ai_response_output').appendChild(newDiv);

    const element = document.getElementById('ai_response_output').parentElement;
      if (element) {
        element.scrollTop = element.scrollHeight;
      }

    // Clear the input field
    this.hiddenBodyTarget.value = questionText;
    this.inputTarget.value = "";
    // Submit the form using Turbo (this triggers the controller action in Rails)
    this.formTarget.requestSubmit();
  }
}
